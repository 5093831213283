/* 
  Default styles for all pages. 
  Think of this as a global stylesheet for the pages directory.
*/

/* HOME PAGE - MEDIA QUERIES */

/* Media Query for low resolution from mobile devices up to Tablets, Ipads */
@media (max-width: 1024px) {
  body {
    background-color: #9e4e2c;
    color: white;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 1024px) and (max-width: 1200px) {
  body {
    background-color: #9e4e2c;
    color: white;
  }
}

/* PROJECTS PAGE - MEDIA QUERIES */
