/* Application global stylesheet */

#video {
  height: 400px;
  position: fixed;
  border-radius: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 40%;
}