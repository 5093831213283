section#introduction {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  justify-content: center;
  align-self: center;
}
section#about {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
section#frontend-experience {
  height: 100vh;
  background-color: #b65c31;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
}
section#backend-experience {
  background-color: #9e4e2c;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div#image-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* MEDIA QUERIES */
@media (max-width: 1200px) {
  section#introduction {
    grid-template-columns: 1fr;
  }
  section#about {
    margin-bottom: 120px;
  }
  section#backend-experience {
    margin: 120px auto;
  }
}