/* footer specific stylesheet */

.fa {
  color: #9e4e2c;
  text-decoration: none;
  cursor: pointer;
}

/* MEDIA QUERIES */
@media (max-width: 1024px) {
  .fa {
    color: white;
  }
}